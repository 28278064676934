import { template as template_3c67692eab204cf9a9a195579eb94afa } from "@ember/template-compiler";
const FKText = template_3c67692eab204cf9a9a195579eb94afa(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
