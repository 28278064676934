import { template as template_4c48107af0b6406293233072008aa95a } from "@ember/template-compiler";
const FKControlMenuContainer = template_4c48107af0b6406293233072008aa95a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
